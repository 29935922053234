import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import SignupForm, { SignupFormUserStub } from 'pages/_serverRendered/EventSignup/SignupForm'
import RealmLogo from 'components/MarketingSite/Header/RealmLogo'
import CoreInput from 'components/Core/CoreInput'
import CoreText from 'components/Core/CoreText'
import { InlineWidget } from 'react-calendly'
import CoreButton from 'components/Core/CoreButton'
import { useWindowHeight } from 'utils/windowHeight'
import { useAdjustBanner } from 'non-rendering/ShowOrHideAdjustBanner'
import { eventSelectedTeamMemberState, eventsSelectedMarketState } from 'recoil/events'
import { RecoilRoot, useRecoilState } from 'recoil'
import QRCode from 'react-qr-code'
import { patchFormData } from 'utils/fetch'

interface Props {
  events_team_members: string[] | null
  sales_events: { name: string; event_type: string | null; event_category: string | null }[]
  webview: boolean
  calendly_url: string
}

type EventSignupMode = 'user-info' | 'schedule-meeting' | 'prompt-download' | 'user-notes' | 'prompt-meeting'

const Inner: FC<Props> = ({ events_team_members, calendly_url, sales_events }) => {
  const [mode, setMode] = useState<EventSignupMode>('schedule-meeting')
  const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null)
  const [user, setUser] = useState<SignupFormUserStub | null>(null)

  const [eventName, setEventName] = useRecoilState(eventsSelectedMarketState)
  const [referrer, setReferrer] = useRecoilState(eventSelectedTeamMemberState)

  const windowHeight = useWindowHeight()

  const { hideAdjustBanner } = useAdjustBanner()
  useEffect(() => hideAdjustBanner(), [hideAdjustBanner])

  const handleReset = useCallback(() => {
    setUser(null)
    setMode('user-info')
  }, [])

  const handleSwitchMode = useCallback((value: EventSignupMode) => {
    return () => setMode(value)
  }, [])

  const handleSuccess = useCallback(
    (user: SignupFormUserStub) => {
      if (!user.email?.length) handleReset()
      else {
        setUser(user)
        setMode(calendly_url ? 'prompt-meeting' : 'prompt-download')
      }
    },
    [calendly_url, handleReset]
  )

  const handleSubmitNotes = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      if (!user) return

      try {
        patchFormData(`/api/v1/users/${user.id}/signup_message`, new FormData(event.target as HTMLFormElement))
        setAlert({ type: 'success', message: 'User notes saved!' })
      } catch (e) {
        console.error(e)
        setAlert({ type: 'error', message: 'Error saving user notes' })
      }

      handleReset()
    },
    [user, handleReset]
  )

  useEffect(() => {
    if (window) window.scrollTo(0, 0)
  }, [user, mode])

  useEffect(() => {
    if (sales_events.some((x) => x && x.name == eventName) == false) setEventName('')
    if (events_team_members?.includes(referrer) == false) setReferrer('')
  }, [setEventName, sales_events, eventName, events_team_members, referrer, setReferrer])

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-gradient-to-b tw-from-green-900 tw-to-neutrals-1000">
      {user && calendly_url && mode == 'schedule-meeting' ? (
        <div className="tw-relative">
          <div className="tw-flex !tw-absolute tw-left-0 tw-top-0  tw-m-4 tw-gap-2">
            <CoreButton text="Start over" kind="secondary" size="s" onClick={handleReset} className="!tw-w-24" />
            <CoreButton
              text="Add notes"
              kind="secondary"
              size="s"
              onClick={handleSwitchMode('user-notes')}
              className="!tw-w-24"
            />
          </div>
          <InlineWidget
            url={calendly_url}
            styles={{ width: '100vw', height: `${windowHeight}px` }}
            prefill={{ email: user.email, name: user.full_name, customAnswers: { a1: user.phone_number } }}
            utm={{ utmSource: 'event-signup' }}
          />
        </div>
      ) : (
        <>
          <div className="tw-flex tw-py-6 sm:tw-y-8 tw-justify-center tw-items-center">
            <RealmLogo className="tw-w-20 sm:tw-w-24 tw-text-white" />
          </div>
          <div className="tw-bg-neutrals-100 tw-mb-16 tw-space-y-6 tw-mx-2 sm:tw-mx-auto tw-p-4 sm:tw-p-8 tw-rounded-3xl sm:tw-rounded-5xl sm:tw-w-sm">
            {!user ? (
              <SignupForm
                alert={alert}
                setAlert={setAlert}
                hideNotes
                onSuccess={handleSuccess}
                header={
                  <div className="tw-space-y-4 tw-pb-4 tw-border-b">
                    <CoreText.Headline
                      className="tw-text-center tw-text-4xl sm:tw-text-left tw-mb-8 tw-mt-4 sm:tw-mt-0"
                      size="xs"
                    >
                      {'Event Signup'}
                    </CoreText.Headline>
                    <CoreInput.Select
                      name="user[sfdc_lead_attrs][Name_of_market__c]"
                      label="Name of event"
                      required
                      value={eventName}
                      onChange={setEventName}
                      allowBlank
                    >
                      {sales_events.map((event) => (
                        <option key={event.name} value={event.name}>
                          {event.name}
                        </option>
                      ))}
                    </CoreInput.Select>

                    <CoreInput.Select
                      value={referrer}
                      onChange={setReferrer}
                      name="user[sfdc_lead_attrs][Lead_Captured_By__c]"
                      label="Referred by"
                      required
                      allowBlank
                    >
                      {events_team_members &&
                        events_team_members.map((teamMember) => (
                          <option key={teamMember} value={teamMember}>
                            {teamMember}
                          </option>
                        ))}
                    </CoreInput.Select>
                  </div>
                }
                className="tw-space-y-4"
                addressOptional
                emailOptional
              >
                <input type="hidden" name="bypass_signin" value="true" />
                <input type="hidden" name="user[non_app_signup_type]" value="non-homeshow-event" />
              </SignupForm>
            ) : mode == 'prompt-download' ? (
              <div className="tw-flex tw-flex-col tw-gap-8 tw-items-center">
                <CoreText.Headline size="xs">
                  {'Download our award-winning app to save time and money on your renovations!'}
                </CoreText.Headline>
                <QRCode value={`https://realmhome.com/mr/client-onboarding/${user.email}/default`} />
                <CoreButton className="tw-w-full" text="Skip" onClick={handleReset} kind="secondary" />
              </div>
            ) : mode == 'prompt-meeting' ? (
              <div className="tw-space-y-4">
                <CoreText.Headline size="xs" className="tw-mb-8">
                  {'Do you want to schedule a meeting?'}
                </CoreText.Headline>
                <CoreButton
                  className="tw-w-full"
                  text="Yes"
                  onClick={handleSwitchMode('schedule-meeting')}
                  kind="primary"
                />
                <CoreButton
                  className="tw-w-full"
                  text="Add user notes"
                  onClick={handleSwitchMode('user-notes')}
                  kind="secondary"
                />
                <CoreButton
                  className="tw-w-full"
                  text="Skip"
                  onClick={handleSwitchMode('prompt-download')}
                  kind="secondary"
                />
              </div>
            ) : mode == 'user-notes' && !!user ? (
              <form onSubmit={handleSubmitNotes} className="tw-space-y-4">
                <CoreText.Headline
                  className="tw-text-center tw-text-4xl sm:tw-text-left tw-mb-8 tw-mt-4 sm:tw-mt-0"
                  size="xs"
                >
                  {`Notes for ${user.full_name}`}
                </CoreText.Headline>
                <CoreInput.TextArea name="user[signup_message]" label="Anything else you want to share?" />
                <CoreButton type="submit" text="Submit" className="tw-w-full" />
              </form>
            ) : null}
          </div>
          <div className="tw-grow" />
        </>
      )}
    </div>
  )
}

const EventSignup: FC<Props> = (props) => {
  return (
    <RecoilRoot>
      <Inner {...props} />
    </RecoilRoot>
  )
}

export default EventSignup
